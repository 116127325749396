<template>
  <b-row v-if="Object.values(getStatistics()).length">
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="ActivityIcon"
        :statistic="getStatistics().projects.active"
        statistic-title="Progetti attivi"
        color="info"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="ArchiveIcon"
        :statistic="getStatistics().projects.expired"
        statistic-title="Progetti conclusi"
        color="secondary"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="UserIcon"
        :statistic="getStatistics().users.total"
        statistic-title="Utenti totali"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="UsersIcon"
        :statistic="getStatistics().classes.total"
        statistic-title="Classi"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="XIcon"
        :statistic="getStatistics().matches.todo"
        statistic-title="Match da eseguire"
        color="danger"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="MinusIcon"
        :statistic="getStatistics().matches.doing"
        statistic-title="Match in esecuzione"
        color="warning"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="CheckIcon"
        :statistic="getStatistics().matches.done"
        statistic-title="Match eseguiti"
        color="success"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="AlertCircleIcon"
        :statistic="getStatistics().matches.withoutQuiz"
        statistic-title="Match senza quiz assegnato"
        color="warning"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="AlertCircleIcon"
        :statistic="getStatistics().matches.withoutStartTime"
        statistic-title="Match senza data di inizio"
        color="warning"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="CheckSquareIcon"
        :statistic="getStatistics().quiz.total"
        statistic-title="Quiz totali"
        color="info"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="BookOpenIcon"
        :statistic="getStatistics().trainings.total"
        statistic-title="Trainings totali"
        color="info"
      />
    </b-col>
    <b-col
      xl="3"
      lg="3"
      md="6"
      sm="12"
      cols="12"
    >
      <statistic-card-horizontal
        icon="FileIcon"
        :statistic="getStatistics().pressrooms.total"
        statistic-title="Pressrooms totali"
        color="info"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { isUserLoggedIn } from '@/auth/utils'
import useStatisticsList from '@/views/dashboard/useStatisticsList'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
      text: null,
      paymentMethod: null,
    }
  },
  beforeCreate() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  setup() {
    const {
      fetchStatistics,
      getStatistics,
    } = useStatisticsList()
    fetchStatistics()

    return {
      fetchStatistics,
      getStatistics,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
